<template>
  <div v-on:click="clickWeapon()"  v-bind:class="[setClass(), 'max-w-sm', 'rounded', 'overflow-hidden', 'shadow-lg', 'm-5']">
    <div v-bind:class="['w-full', 'bg-white', 'h-52', 'item-center', '']" >
      <img :src="img_filename" class="pl-20 h-full content-center" alt="">
    </div>
  <div class="px-6 py-4">
    <div class="font-bold text-xl mb-2">{{weapon.name}}</div>
    <p class="text-gray-700 text-base">
      <span v-html="weapon.description"></span>
    </p>
  </div>
  <div class="px-6 pt-4 pb-2">
    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#tool</span>
  </div>

  </div>
</template>

<script>
import EventBus from '../../../event-bus.js'
export default {
  name: 'Weapon',
  data: function(){
    return {
      useIt: false,
      img_filename: null
    }
  },
  computed: {

  },
  created() {

    EventBus.$on('turnOffWeapon', this.turnOffWeapon);
    EventBus.$on('setWeapon', this.checkThisWeapon);
  },
  methods: {
    clickWeapon(){
      if(this.weapon.was_used == 1){
        return false;
      }
      this.useIt = !this.useIt;
      if (this.useIt) {
        EventBus.$emit('setWeapon', this.weapon.key);
      }
    },
    turnOffWeapon(){
      //EventBus.$emit('turnOffWeapon');
      this.useIt = false;

    },
    checkThisWeapon(key){
      if(this.weapon.key != key){
        this.useIt = false;
      }

    },
    setClass(){
      this.img_filename = require("../../../assets/img/weapon/" + this.weapon.weapon_info.filename);
      if(this.weapon.was_used == 1){
        return "bg-gray-400"
      }
      if (!this.useIt) {
        return "bg-green-400"
      }else{
        return "bg-red-400"
      }
      //alert(this.weapon.key);
    }
  },
  props: ['weapon']
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
