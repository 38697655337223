<template>
  <div id="logs-modal" class="modal">
    <div class="modal-box">
      <Logs />
      <div class="modal-action">
        <a href="#" class="btn">{{ $t('close_btn_title')}}</a>
      </div>
    </div>
  </div>
</template>
<script>
import Logs from '../Logs.vue'
export default {
  name: 'ModalLogs',
  components: {
     Logs
  },
  data: function(){
    return {

    }
  },
  computed: {

  },
  mounted() {

  },
  created() {

  },
  methods: {

  },
  props: ["settings"]
}
</script>
