<template>
  <div>
    <div>
      <div class="grid grid-cols-2 gap-2">
        <div>
          
        </div>
        <div class=" text-right  ">
          <span  @click="closeSlot" class="text-red-500 text-xl  shadow-2xl">{{ $t('close_btn_title') }}</span>
        </div>
      </div>
    </div>

    
    <div class="bg-white p-12 text-2xl">
      <div v-if="showDefeatText">{{ $t("do_you_really_want_to_leave_this_game_defeat") }}</div>
      <div v-else>{{ $t("do_you_really_want_to_leave_this_game") }}</div>
      <div @click="leave" class="text-sm text-blue-600 hover:underline pt-6">{{ $t("leave") }}</div>
    </div>
          
          
        
  </div>

</template>
<script>
import EventBus from '../../../event-bus.js'
import axios from 'axios'
export default {
  name: 'Leave',
  components: {
     
  },
  data: function(){
    return {
      showDefeatText: false
    }
  },
  computed: {

  },
  mounted() {

    if(this.players.length == 2){
      this.showDefeatText = true;
    }
  },
  created() {

  },
  methods: {
    closeSlot() {
      EventBus.$emit('showControlItem', 'home');
    },
    async leave() {
        console.log(this.gk);
        if (this.user.token == null) {
          return false;
        }

        if (this.user.token == undefined) {
          return false;
        }

        if (this.user.token == '') {
          return false;
        }

        this.waitingJoinResult = 1;
        await axios
          .post(this.$backend + '/game/player/leave', { gk: this.gk}, {
            headers: {
              Authorization: 'Bearer ' + this.user.token //the token is a variable which holds the token
            }
          }
          )
          .then(response => {
            if(typeof response.data.m !== 'undefined'){
              this.$notify({
                group: 'default',
                type: 'warn',
                //title: 'Important message',
                text: response.data.m
              });
              this.waitingJoinResult = 0;
              return false;
            }
            this.$router.push('/'+this.$i18n.locale+'/')
            this.$cookie.delete('playerId');
            this.$cookie.delete('gk');
          })
          .catch(error => {

            console.log('----->'+error)
            if (error.response.status == 401) {
              this.mustBeLogin = true
              return false;
            }

            this.$notify({
              group: 'default',
              type: 'error',
              title: 'Server error',
              text: error
            });
          });
    }
  },
  props: ['user', 'gk', 'players']
}
</script>
