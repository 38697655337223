<template>
    <div class="flex items-center justify-center min-h-screen ">
        <div class="px-8 py-6 mt-4 text-left shadow-lg border-2">
          <div class="w-full flex justify-end ">
            <div class="item-right ">
              <router-link :to="{ name: 'GamesList', params: { lang: $i18n.locale }}">
                <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="m12.45 37.65-2.1-2.1L21.9 24 10.35 12.45l2.1-2.1L24 21.9l11.55-11.55 2.1 2.1L26.1 24l11.55 11.55-2.1 2.1L24 26.1Z"/></svg>
              </router-link>
            </div>
          </div>
            <h3 class="text-2xl font-bold text-center">{{ $t("registration") }}</h3>
            <div>
                <div class="mt-4">
                    <div>
                        <label class="block" for="name">{{ $t("name") }}</label>
                                <input type="text" placeholder="" v-model="name"
                                    class="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none text-black focus:ring-1 focus:ring-blue-600">
                    </div>
                    <div>
                        <label class="block" for="email">Email</label>
                                <input type="text" placeholder="" v-model="email"
                                    class="w-full px-4 py-2 mt-2 border text-black rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600">
                    </div>
                    <div class="mt-4">
                        <label class="block">{{ $t("password") }}</label>
                                <input type="password" placeholder="" v-model="password" name="confirmPassword" @keypress="validateForm" @click="validateForm"
                                    class="w-full px-4 py-2 mt-2 border text-black rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600">
                    </div>
                    <div>
                      <small class="text-xs" :class="{'text-red-500': !checkPassResult.length }">{{ $t("pass_valid_messages.length") }}</small>
                    </div>
                    <div>
                      <small class="text-xs hidden" :class="{'text-red-500': !checkPassResult.number }">{{ $t("pass_valid_messages.number") }}</small>
                    </div>
                    <div>
                      <small class="text-xs hidden" :class="{'text-red-500': !checkPassResult.special }">{{ $t("pass_valid_messages.special") }}</small>
                    </div>
                    <div>
                      <small class="text-xs hidden" :class="{'text-red-500': !checkPassResult.lowercase }">{{ $t("pass_valid_messages.lowercase") }}</small>
                    </div>
                    <div>
                      <small class="text-xs hidden" :class="{'text-red-500': !checkPassResult.uppercase }">{{ $t("pass_valid_messages.uppercase") }}</small>
                    </div>
                    <div class="mt-4">
                        <label class="block">{{ $t("confirm_password") }}</label>
                                <input type="password" placeholder="" v-model="confirmPassword" name="confirmPassword" @keypress="validateForm" @click="validateForm"
                                    class="w-full px-4 py-2 mt-2 border text-black rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600">
                    </div>
                    <div>
                      <small class="text-xs" :class="{'text-red-500': !checkPassResult.confirm }">{{ $t("pass_valid_messages.confirm") }}</small>
                    </div>
                    <div v-if="(errorMessage !== null)" class="mt-4">
                      <span class="text-red-500">{{ $t(errorMessage) }}</span>
                    </div>
                    <div class="flex items-baseline justify-between">
                        <button @click="Registration" class="px-6 py-2 mt-4 text-white bg-blue-600 rounded-lg hover:bg-blue-900">{{ $t("registration") }}</button>
                        <router-link :to="{ name: 'Login', params: { lang: $i18n.locale} }">
                          <div class="text-sm text-blue-600 hover:underline">{{ $t("login") }}</div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import EventBus from '../../event-bus.js'
export default {
  name: 'Registration',
  components: {

  },
  data: function(){
    return {
      name: null,
      email: null,
      password: null,
      confirmPassword: null,
      errorMessage: null,
      checkPassResult: {
        uppercase: true,
        lowercase: true,
        length: true,
        special: true,
        number: true,
        confirm: true
      }
    }
  },
  computed: {

  },
  mounted() {
    document.title = "In Square Game. Registration";
    //EventBus.$emit('updateGameStatus', 'lg');
  },
  created() {

  },
  methods: {
    Registration(){
      this.errorMessage = null
      if(!this.validateForm()){
        console.log('no valid');
        return false;
      }

      axios
          .post(this.$backend + '/register', { name: this.name, email: this.email, password: this.password, lang: this.$i18n.locale  })
          .then(response => {
            //если ошибка
            console.log('response.data');
            console.log(response.data);
            if(typeof response.data.m !== 'undefined'){
                this.$notify({
                group: 'default',
                type: 'error',
                title: 'Error',
                text: response.data.m
                });
                return false;
            }
            console.log('response.data.access_token');
            console.log(response.data.access_token);
            if(typeof response.data.access_token !== 'undefined'){
              if(response.data.access_token !== '' ){
                    console.log(response.data.access_token);
                    EventBus.$emit("setUserToken", response.data.access_token);
                    this.$cookie.set('usertoken', response.data.access_token, { expires: '1Y' });
                    this.$router.push('/'+this.$i18n.locale)

                }
            }
          })
          .catch(error => {
            console.log(error);

            if (error.response.status == 401) {
              this.errorMessage = (error.response.data.message);
              return false;
            }
            if (error.response.status == 422) {
              console.log(error.response.data.errors);
              this.errorMessage = (error.response.data.errors.email[0]);
              return false;
            }


            this.$notify({
              group: 'default',
              type: 'error',
              title: 'Server error',
              text: error
            });
          });
    },
    hasNumber(string) {
      return /\d/.test(string);
    },
    hasLowLetter(string) {
      if( /[a-z]/.test(string) ) {
        return true;
      }else{
        return false;
      }
    },
    hasUpLetter(string) {
      if( /[A-Z]/.test(string) ) {
        return true;
      }else{
        return false;
      }
    },
    hasSpecSumb(string) {
      let specSymbls = ['`', '№', '~', '!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '-', '_', '=', '+', '/', '|', '<', '>', '"', ';', ':', "'", ']', '[', '{', '}', '?', '\\']; 
      for (let i = 0; i < specSymbls.length; i++) {
        if(string.includes(specSymbls[i])){
          return true;
        }
      }
      return false;
    },

    validateForm(){
      let hasError = false

      this.checkPassResult.number = true;
      this.checkPassResult.lowercase = true;
      this.checkPassResult.uppercase = true;
      this.checkPassResult.special = true;
      this.checkPassResult.length = true;
      this.checkPassResult.confirm = true;

      if(!this.hasNumber(this.password)){
        console.log('no num pass');
        //this.checkPassResult.number = false;
        //hasError = true
      }
      if(!this.hasLowLetter(this.password)){
        console.log('no low letter pass');
        //this.checkPassResult.lowercase = false;
        //hasError = true
      }
      if(!this.hasUpLetter(this.password)){
        console.log('no Up letter pass');
        //this.checkPassResult.uppercase = false;
        //hasError = true
      }
      if(!this.hasSpecSumb(this.password)){
        console.log('no spec pass');
        //this.checkPassResult.special = false;
        //hasError = true
      }
      if(this.password == null){
        console.log('2 wrong pass');
        hasError = true
      }
      if(this.password.length < 8){
        console.log('3 wrong pass');
        this.checkPassResult.length = false;
        hasError = true
      }
      if(this.confirmPassword !== this.password){
        console.log('4 wrong pass');
        this.checkPassResult.confirm = false;
        hasError = true
      }
      return !hasError;
    }
  },

  props: []
}
</script>
