<template>
    <div> 
        <span class="text-black">{{ countDown }}</span>
    </div>

 </template>
 
 <script>
 import EventBus from '../../event-bus.js'
 import axios from 'axios'

     export default {
        name: 'Timer',
         data () {
             return {
                 countDown: 60,
                 startDate: null,
                 endDate: null,
                 keyForSkip: null,
                 gameId: null
             }
         },
         created () {
             //this.countDownTimer()
             EventBus.$on("setQueue", this.Go);
         },
         props: ['user'],
         methods: {
             countDownTimer () {
                 if (this.countDown > 0) {
                     setTimeout(() => {
                        var endDate   = new Date();
                        var seconds = 0;
                        seconds = Math.round((endDate.getTime() - this.startDate.getTime()) / 1000);
                        this.countDown = 60 - seconds
                        this.countDownTimer()
                     }, 1000)
                 }
                 if(this.countDown == 0){
                    this.finish();
                 }
                 if(this.countDown < 0){
                    this.countDown = 0;
                 }
                 
             },
             Go(data){
                this.countDown = 60;
                this.startDate = new Date();
                this.countDownTimer()
                this.keyForSkip = data.key_for_skip
                this.gameId = data.game_id
             },
             async finish(){
                await axios.post(this.$backend + '/game/queue/skip', {'game_id': this.gameId, 'key_for_skip': this.keyForSkip}, {
                    headers: {
                    Authorization: 'Bearer ' + this.user.token //the token is a variable which holds the token
                    }
                })
                .then(response => {
                    //если ошибка
                    console.log(response);
                    
                })
                .catch(error => {
                    console.log(error);
                });
             }
         }
     }
 </script>