import Vue from 'vue'
import App from './App.vue'
//import socketio from 'socket.io';
import VueSocketIO from 'vue-socket.io';
import SocketIO from 'socket.io-client'
import Notifications from 'vue-notification'
import VueUp from 'vueup'
import router from './router'
import './index.css'
import i18n from './i18n'
import VueFab from 'vue-float-action-button'

var VueCookie = require('vue-cookie');

Vue.use(new VueSocketIO({
    debug: true,
    connection: SocketIO(process.env.VUE_APP_SOCKET)
  })
);
Vue.prototype.$backend = process.env.VUE_APP_BACKEND;

Vue.use(VueCookie)
Vue.use(Notifications)
Vue.use(VueUp)
Vue.use(VueFab)

// use beforeEach route guard to set the language
router.beforeEach((to, from, next) => {

  // use the language from the routing param or default language
  let language = to.params.lang;
  if (!language) {
    language = 'en'
  }

  // set the current language for i18n.
  i18n.locale = language
  next()
})

new Vue({
  render: h => h(App),
  i18n,
  router
}).$mount('#app')
