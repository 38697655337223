<template>
  <div class="">
    <div class="grid grid-cols-2 gap-2">
      <div>
        <h3 class="text-white text-2xl font-black shadow-2xl">{{ $t("players") }}</h3>
      </div>
      <div class="text-right">
        <span  @click="closeSlot" class="text-red-500 text-xl  shadow-2xl">{{ $t('close_btn_title') }}</span>
      </div>
    </div>
    <div v-for="(player) in players" v-bind:player="player" v-bind:key="player.id" v-bind:playerColor="playersColor[player.id]">
     <div class="bg-white p-8 dark:bg-slate-800 shadow-2xl">
        <!-- player block -->
        <div class="grid grid-cols-5 gap-3">
          <div v-bind:class="['bg-'+playersColor[player.id].color, 'flex-shrink-0', 'w-12', 'h-12', 'items-center', 'justify-center']"></div>
          <div class="">
            <blockquote>
              <p class="text-lg font-medium dark:text-white	">
                {{player.name}}
              </p>
            </blockquote>
            <figcaption class="font-medium">
              <div>
                {{player.f}}
              </div>
            </figcaption>
          </div>
        </div>
        <!-- /player block -->
      </div>
    </div>
  </div>
</template>
<script>
import EventBus from '../../../event-bus.js'
export default {
  name: 'PlayersList',
  components: {

  },
  data: function(){
    return {
      // players: [],
      // playerColor: []
    }
  },
  computed: {

  },
  mounted() {

  },
  created() {
    console.log(this.playersColor);
  },
  methods: {
    closeSlot() {
      EventBus.$emit('showControlItem', 'home');
    }

  },
  props: ['players', 'playersColor']
}
</script>
