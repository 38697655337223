<template>
  <div>
  <div id="gameListPageDiv" class="max-w-2xl mx-auto m-4">
    <!-- desctop menu-->
    <div class="hidden sm:flex ">
      <div class="mt-1 flex-auto">
        <h1 class="text-xl font-bold leading-none text-pink-500 dark:text-white my-5">In Square Game</h1>
      </div>
      <div class=" justify-items-center w-1/5 ">
        <div class="flex flex-row">
            <div @click="setLocale('en')" :class="[ $i18n.locale == 'en' ? 'bg-green-500': 'bg-black']" class="mt-3 text-white border border cursor-pointer h-10 px-2 py-2">EN</div>
            <div @click="setLocale('ru')" :class="[ $i18n.locale == 'ru' ? 'bg-green-500': 'bg-black']" class="mt-3 text-white ml-2 border cursor-pointer h-10 px-2 py-2">RU</div>
        </div>
      </div>
      <div class="flex-auto w-2/5">
        <div class="grid grid-cols-3 gap-0 justify-items-end ">
          <div class=" ">   
            <div class="my-5">
              <span v-if="$i18n.locale == 'ru'"><a href="https://help.insquare.online/ru/how-to-play">Как играть</a></span>
              <span v-else><a href="https://help.insquare.online/how-to-play">How to play</a></span>
            </div>
          </div>
          <div class=" ">
            <router-link :to="{ name: 'LeadersTable', params: { lang: $i18n.locale, settings: settings }}">
            <div class="my-5">
              <div class="text-center">{{ $t('leaders') }}</div>
            </div>
          </router-link>
          </div>
          <div class=" ">
            <div @click="userInfoClk" class="my-5 cursor-pointer	">
              <div v-if="this.user.token == null || this.user.token == ''" class="text-center">{{ $t("login") }}</div>
              <div v-else class="text-center">{{ this.user.name }}</div>
            </div>
          </div>
        </div>

      </div>
    </div>
      <!-- /desctop menu-->
      <!-- mobile menu-->
    <div class="flex sm:hidden">
      <div class="mt-1 ml-4 flex-auto">
        <h1 class="text-xl font-bold leading-none text-pink-500 dark:text-white my-5">In Square Game</h1>
      </div>
      <div class=" justify-items-center w-1/3 ">
        <div class="flex flex-row">
            <div @click="setLocale('en')" :class="[ $i18n.locale == 'en' ? 'bg-green-500': 'bg-black']" class="mt-3 border border h-8 px-1 py-1">EN</div>
            <div @click="setLocale('ru')" :class="[ $i18n.locale == 'ru' ? 'bg-green-500': 'bg-black']" class="mt-3 ml-2 border h-8 px-1 py-1">RU</div>
        </div>
      </div>
    </div>
      <div class="w-full flex sm:hidden">

        <div class="grid grid-cols-3 gap-3 ml-4 justify-items-center ">

          <div class=" ">   
            <div class="my-2 mr-3">
              <span v-if="$i18n.locale == 'ru'"><a href="https://help.insquare.online/ru/how-to-play">Как играть</a></span>
              <span v-else><a href="https://help.insquare.online/how-to-play">How to play</a></span>
            </div>
          </div>

          <div class=" ">
            <router-link :to="{ name: 'LeadersTable', params: { lang: $i18n.locale, settings: settings }}">
            <div class="my-2">
              <div class="text-center">{{ $t('leaders') }}</div>
            </div>
          </router-link>
          </div>

          <div class="">
            <div @click="userInfoClk" class=" cursor-pointer my-2">
              <div v-if="this.user.token == null || this.user.token == ''" class="text-center">{{ $t("login") }}</div>
              <div v-else class="text-center">{{ this.user.name }}</div>
            </div>
          </div>

        </div>
      </div>
    <!-- /mobile menu-->
    <div class="p-4 md:border shadow-md sm:p-8 dark:bg-black dark:border-gray-700">
        <div class="flex justify-between items-center mb-4">
            <h3 class="text-xl font-bold leading-none">{{ $t('game_list_title')}}</h3>
            <div v-on:click="getList()" class="relative w-fit h-fit px-4 py-1 text-lg border  border-black cursor-pointer	">
              <span>{{ $t('update_btn_title')}}</span>
            </div>
       </div>
       <div class="flow-root">
            <ul role="list" class="divide-y divide-gray-200 dark:divide-gray-700">
                <li v-for="(ld, row) in listData" v-bind:key="row" class="py-3 sm:py-4">
                  <router-link :to="{ name: 'Play', params: { id: ld.gk, lang: $i18n.locale, settings: settings }}">
                    <div class="flex text-left space-x-4">
                        <div class="flex-shrink-0">
                            <div  v-bind:class="['w-12', 'h-12', getRanomColor()]"></div>
                        </div>
                        <div class="flex-1 min-w-0">
                            <p class="text-sm font-medium truncate dark:text-white">
                                <b>{{(ld.name.split(' ')[0].toUpperCase())}} {{(ld.name.split(' ')[1].toUpperCase())}}</b>
                            </p>
                            <p class="text-sm text-gray-500 truncate dark:text-gray-400">
                                {{ld.mt}}
                            </p>
                        </div>
                        <div class="inline-flex items-center text-base font-semibold light:text-gray-900 dark:text-white">
                          {{ld.jp}}  / {{ld.pc}}
                        </div>
                    </div>
                  </router-link>
                </li>

            </ul>
       </div>
    </div>
  </div>


</div>

</template>

<script>
import axios from 'axios'
import EventBus from '../event-bus.js'


export default {
  name: 'GamesList',
  components: {

  },
  data: function(){
    return {
      listData: null,
      drawerShow: false,
      colors: ['bg-green-200', 'bg-purple-400', 'bg-purple-200','bg-blue-200', 'bg-indigo-500', 'bg-purple-400', 'bg-pink-400', 'bg-red-500', 'bg-black', 'bg-yellow-300']
    }
  },
  computed: {

  },
  mounted() {
    document.title = "In Square Game. Playground";
    EventBus.$emit('updateGameStatus', 'lg');
  },
  created() {
    this.getList()
    console.log(this.settings);
  },
  methods: {
    
    setLocale(locale) {
      this.$i18n.locale = locale
      EventBus.$emit('updateLang', locale);
      this.$router.push({
        params: { lang: locale }
      })
    },

    getRanomColor(){
      var mainColor = this.colors[Math.floor(Math.random()*this.colors.length)];
      var hoverColor = 'hover:'+this.colors[Math.floor(Math.random()*this.colors.length)];
      return [mainColor, hoverColor]
    },
    userInfoClk(){
      if(this.user.token == null || this.user.token == '' ){
        this.$router.push('/'+this.$i18n.locale+'/login')
      }else{
        this.$router.push('/'+this.$i18n.locale+'/profile/me')
      }
        
  
    },
    getList(){
      axios
          .post(this.$backend + '/games/list')
          .then(response => {
            //если ошибка

            if(typeof response.data.m !== 'undefined'){
              return false;
            }
            this.listData = response.data
          })
          .catch(error => {
            console.log(error);
            this.$notify({
              group: 'default',
              type: 'error',
              title: 'Server error',
              text: error
            });
          });
    },//getLogs
  },

  props: ['settings', 'user']
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .column {
      display: table-cell;
  }

.navbar {
  transition: all 330ms ease-out;
}

.drawer-open {
  visibility: hidden;
  opacity: 0;
  transform: translate-x-0;
}
.drawer-close {
  visibility: visible;
  transform: translateX(0%);
}
</style>
