<template>
    <div class="flex items-center justify-center min-h-screen ">
        <div class="px-8 py-6 mt-4 text-left shadow-lg border-2">
            <h3 class="text-2xl font-bold text-center">{{ $t("email_valid_messages.form_title") }}</h3>
            <div v-if="canShowForm">
            <div>
                <div class="mt-4">
                  <h1>{{ $t("email_valid_messages.success_message") }}</h1>
                    <div class="flex items-baseline justify-between">
                        <router-link :to="{ name: 'GamesList', params: { lang: $i18n.locale} }">
                          <div class="text-sm text-blue-600 hover:underline">{{ $t("back") }}</div>
                        </router-link>
                    </div>
                </div>
            </div>
            </div>
            <div class="mt-10 mb-10" v-if="success_message">
              <span class="text-green-500">{{ $t("email_valid_messages.success_message") }}</span>
              <router-link :to="{ name: 'GamesList', params: { lang: $i18n.locale} }">
                <div  class="text-big text-center mt-5 text-blue-600  hover:underline">{{ $t("back") }}</div>
              </router-link>
            </div>
            <div class="mt-10 mb-10" v-if="notoken_message">
              <span class="text-big text-center mt-5 text-red-600">{{ $t("email_valid_messages.nolink_message") }}</span>
              <router-link :to="{ name: 'GamesList', params: { lang: $i18n.locale} }">
                <div  class="text-big text-center mt-5 text-blue-600  hover:underline">{{ $t("back") }}</div>
              </router-link>
              
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
  name: 'ValidateEmail',
  components: {

  },
  data: function(){
    return {
      email: null,
      notoken_message: false,
      noemail_message: false,
      success_message: false,
      bad_pass_message: false,
      canShowForm: false,
      token: null,
      password: null,
      confirmPassword: null,
      checkPassResult: {
        uppercase: true,
        lowercase: true,
        length: true,
        special: true,
        number: true,
        confirm: true
      }
    }
  },
  computed: {

  },
  mounted() {
    document.title = "In Square Game. Valid Email";
  },
  created() {
    this.checkKey();
  },
  methods: {
    checkKey(){
      this.token = this.$route.params.key;
      axios
          .post(this.$backend + '/user/email/valid/key', { token: this.token})
          .then(response => {
            //если ошибка
            console.log(response.data);
            if(response.data.success == "ok") this.canShowForm = true;
            if(response.data.m == "no_token") this.notoken_message = true;
            if(response.data.m == "no_user") this.notoken_message = true;
            
            
          })
          .catch(error => {
            console.log(error);
            this.$notify({
              group: 'default',
              type: 'error',
              title: 'Server error',
              text: error
            });
          });
    },
    showForm(){
      this.success_message = false;
      this.noemail_message= false;
      this.bad_pass_message= false;
      this.canShowForm = true; 
    },
    update(){
      if(!this.validateForm()){
        console.log('no valid');
        return false;
      }
      this.success_message = false;
      this.noemail_message= false;
      this.bad_pass_message= false;
      if(this.hasError){
        return false;
      }
      axios
          .post(this.$backend + '/up/restore/update', { token: this.token, password: this.password})
          .then(response => {
            //если ошибка
            console.log(response.data);
            if(response.data.success == "ok"){ this.success_message = true; this.canShowForm = false; }
            if(response.data.m == "no_email"){ this.noemail_message = true; this.canShowForm = false; }
            if(response.data.m == "no_token"){ this.notoken_message = true; this.canShowForm = false; }
            if(response.data.m == "bad_pass") { this.bad_pass_message = true; }
          })
          .catch(error => {
            console.log(error);
            this.$notify({
              group: 'default',
              type: 'error',
              title: 'Server error',
              text: error
            });
          });
    },
    hasNumber(string) {
      return /\d/.test(string);
    },
    hasLowLetter(string) {
      if( /[a-z]/.test(string) ) {
        return true;
      }else{
        return false;
      }
    },
    hasUpLetter(string) {
      if( /[A-Z]/.test(string) ) {
        return true;
      }else{
        return false;
      }
    },
    hasSpecSumb(string) {
      let specSymbls = ['`', '~', '№', '!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '-', '_', '=', '+', '/', '|', '<', '>', '"', ';', ':', "'", ']', '[', '{', '}', '?', '\\']; 
      for (let i = 0; i < specSymbls.length; i++) {
        if(string.includes(specSymbls[i])){
          return true;
        }
      }
      return false;
    },

    validateForm(){
      let hasError = false

      this.checkPassResult.number = true;
      this.checkPassResult.lowercase = true;
      this.checkPassResult.uppercase = true;
      this.checkPassResult.special = true;
      this.checkPassResult.length = true;
      this.checkPassResult.confirm = true;

      if(!this.hasNumber(this.password)){
        console.log('no num pass');
        this.checkPassResult.number = false;
        hasError = true
      }
      if(!this.hasLowLetter(this.password)){
        console.log('no low letter pass');
        this.checkPassResult.lowercase = false;
        hasError = true
      }
      if(!this.hasUpLetter(this.password)){
        console.log('no Up letter pass');
        this.checkPassResult.uppercase = false;
        hasError = true
      }
      if(!this.hasSpecSumb(this.password)){
        console.log('no spec pass');
        this.checkPassResult.special = false;
        hasError = true
      }
      if(this.password == null){
        console.log('2 wrong pass');
        hasError = true
      }
      if(this.password.length < 8){
        console.log('3 wrong pass');
        this.checkPassResult.length = false;
        hasError = true
      }
      if(this.confirmPassword !== this.password){
        console.log('4 wrong pass');
        this.checkPassResult.confirm = false;
        hasError = true
      }
      return !hasError;
    }
  },

  props: []
}
</script>
