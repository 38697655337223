<template>
  <div>
    <div class="absolute backdrop-filter backdrop-blur-sm h-screen w-full z-10 border left-0 top-0">
      <div class="flex h-screen justify-center items-center">
        <div class="">

        </div>
      </div>
    </div>
    <div class="absolute  h-screen w-full z-20 border left-0 top-0">
      <div class="flex h-screen justify-center items-center">
        <div class="z-20">
            <slot></slot>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
export default {
  name: 'FullScreen',
  components: {

  },
  data: function(){
    return {

    }
  },
  computed: {

  },
  mounted() {

  },
  created() {

  },
  methods: {

  },
  props: {
    playTime: {
      type: String,
      default: '00:00'
    },
    playerScore: {
       type: [Number, String],
      default: '0'
    }
  }
}
</script>
