<template>
  <div class="flex items-center space-x-2 m-2">
    <div class="flex items-center  items-center justify-center">
      <div v-bind:class="['bg-'+playerColor, 'flex-shrink-0', 'w-12', 'h-12', 'items-center', 'justify-center']">
        <span class="inline-block align-middle m-2 text-center text-white text-2xl font-extrabold">
          <span>{{squareData.p}}</span>
        </span>
      </div>
      <div class="ml-4 text-left">
        <span class="text-black">{{squareData.n}}</span>
        <div class="text-sm text-gray-500 text-left">{{squareData.ct}} / {{squareData.cd}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LogItem',
  data: function(){
    return {
      logData: null,
      squareMainClass: "h-12 w-12 border-2 inline-block align-middle text-black text-2xl",
      squareClasses: ["bg-green-300",
                      "bg-yellow-300",
                      "bg-blue-300"
                    ],
      ownerClasses: [],
      me: {
        name: 'Empty',
        id: 0,
        key: '',
      },
    }
  },
  computed: {

  },
  created() {
    console.log(this.playerColor);
  },
  methods: {
    setClass(type){
      return this.playersColor[type];

    },

    createSquareClasses(){
      this.squareClasses= new Map([
        [1, {class: this.squareForestClass}],
        [2, {class: this.squareDesertClass}],
        [3, {class: this.squareWaterClass}]
      ]);
    },
    getPlayerColor( ){
      // console.log(this.playersColors);
      // // if (this.playersColors[player] != undefined) {
      // //   return this.playersColors[player].color;
      // // }
      // console.log('no player: ', player);
      return "bg-gray-400"
    }
  },

  props: ['squareData', 'players', 'playerColor']
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .column {
      display: table-cell;
      margin: 1px;
  }
</style>
