<template>
    <div class="flex items-center justify-center min-h-screen ">
        <div class="px-8 py-6 mt-4 text-left shadow-lg border-2">
            <h3 class="text-2xl font-bold text-center">{{ $t("restore_pass_enter_new_pass.form_title") }}</h3>
            <div v-if="canShowForm">
              <div>
              <small>{{ $t("restore_pass_enter_new_pass.sub_title") }}</small>
            </div>
            <div>
                <div class="mt-4">

                  <div class="mt-4">
                        <label class="block">{{ $t("password") }}</label>
                                <input type="password" placeholder="" v-model="password" name="password" @keypress="validateForm" @click="validateForm"
                                    class="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600">
                    <div>
                      <small class="text-xs" :class="{'text-red-500': !checkPassResult.length }">{{ $t("pass_valid_messages.length") }}</small>
                    </div>
                    <div>
                      <small class="text-xs" :class="{'text-red-500': !checkPassResult.number }">{{ $t("pass_valid_messages.number") }}</small>
                    </div>
                    <div>
                      <small class="text-xs" :class="{'text-red-500': !checkPassResult.special }">{{ $t("pass_valid_messages.special") }}</small>
                    </div>
                    <div>
                      <small class="text-xs" :class="{'text-red-500': !checkPassResult.lowercase }">{{ $t("pass_valid_messages.lowercase") }}</small>
                    </div>
                    <div>
                      <small class="text-xs" :class="{'text-red-500': !checkPassResult.uppercase }">{{ $t("pass_valid_messages.uppercase") }}</small>
                    </div>
                                    
                  </div>
                    <div class="mt-4">
                        <label class="block">{{ $t("confirm_password") }}</label>
                                <input type="password" placeholder="" v-model="confirmPassword" name="confirmPassword" @keypress="validateForm" @click="validateForm"
                                    class="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600">
                    <div>
                      <small class="text-xs" :class="{'text-red-500': !checkPassResult.confirm }">{{ $t("pass_valid_messages.confirm") }}</small>
                    </div>
                    </div>
                    <div v-if="bad_pass_message">
                      <span class="text-red-500">{{ $t("restore_pass_enter_new_pass.badpass_message") }}</span>
                    </div>
                    <div class="flex items-baseline justify-between">
                        <button @click="update" class="px-6 py-2 mt-4 text-white bg-blue-600 rounded-lg hover:bg-blue-900">{{ $t("restore_pass_enter_new_pass.button_title") }}</button>
                        <router-link :to="{ name: 'GamesList', params: { lang: $i18n.locale} }">
                          <div class="text-sm text-blue-600 hover:underline">{{ $t("back") }}</div>
                        </router-link>
                    </div>
                </div>
            </div>
            </div>
            <div class="mt-10 mb-10" v-if="success_message">
              <span class="text-green-500">{{ $t("restore_pass_enter_new_pass.success_message") }}</span>
              <router-link :to="{ name: 'GamesList', params: { lang: $i18n.locale} }">
                <div  class="text-big text-center mt-5 text-blue-600  hover:underline">{{ $t("back") }}</div>
              </router-link>
            </div>
            <div class="mt-10 mb-10" v-if="notoken_message">
              <span class="text-big text-center mt-5 text-red-600">{{ $t("restore_pass_enter_new_pass.notoken_message") }}</span>
              <router-link :to="{ name: 'GamesList', params: { lang: $i18n.locale} }">
                <div  class="text-big text-center mt-5 text-blue-600  hover:underline">{{ $t("back") }}</div>
              </router-link>
              
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
  name: 'RestorePassEnterEmail',
  components: {

  },
  data: function(){
    return {
      email: null,
      notoken_message: false,
      noemail_message: false,
      success_message: false,
      bad_pass_message: false,
      canShowForm: false,
      token: null,
      password: null,
      confirmPassword: null,
      checkPassResult: {
        uppercase: true,
        lowercase: true,
        length: true,
        special: true,
        number: true,
        confirm: true
      }
    }
  },
  computed: {

  },
  mounted() {
    document.title = "In Square Game. New password form";
  },
  created() {
    this.checkKey();
  },
  methods: {
    checkKey(){
      this.token = this.$route.params.key;
      axios
          .post(this.$backend + '/up/restore/check/reset/password/token', { token: this.token})
          .then(response => {
            //если ошибка
            console.log(response.data);
            if(response.data.success == "ok") this.canShowForm = true;
            if(response.data.m == "no_token") this.notoken_message = true;
            
            
          })
          .catch(error => {
            console.log(error);
            this.$notify({
              group: 'default',
              type: 'error',
              title: 'Server error',
              text: error
            });
          });
    },
    showForm(){
      this.success_message = false;
      this.noemail_message= false;
      this.bad_pass_message= false;
      this.canShowForm = true; 
    },
    update(){
      if(!this.validateForm()){
        console.log('no valid');
        return false;
      }
      this.success_message = false;
      this.noemail_message= false;
      this.bad_pass_message= false;
      if(this.hasError){
        return false;
      }
      axios
          .post(this.$backend + '/up/restore/update', { token: this.token, password: this.password})
          .then(response => {
            //если ошибка
            console.log(response.data);
            if(response.data.success == "ok"){ this.success_message = true; this.canShowForm = false; }
            if(response.data.m == "no_email"){ this.noemail_message = true; this.canShowForm = false; }
            if(response.data.m == "no_token"){ this.notoken_message = true; this.canShowForm = false; }
            if(response.data.m == "bad_pass") { this.bad_pass_message = true; }
          })
          .catch(error => {
            console.log(error);
            this.$notify({
              group: 'default',
              type: 'error',
              title: 'Server error',
              text: error
            });
          });
    },
    hasNumber(string) {
      return /\d/.test(string);
    },
    hasLowLetter(string) {
      if( /[a-z]/.test(string) ) {
        return true;
      }else{
        return false;
      }
    },
    hasUpLetter(string) {
      if( /[A-Z]/.test(string) ) {
        return true;
      }else{
        return false;
      }
    },
    hasSpecSumb(string) {
      let specSymbls = ['`', '~', '№', '!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '-', '_', '=', '+', '/', '|', '<', '>', '"', ';', ':', "'", ']', '[', '{', '}', '?', '\\']; 
      for (let i = 0; i < specSymbls.length; i++) {
        if(string.includes(specSymbls[i])){
          return true;
        }
      }
      return false;
    },

    validateForm(){
      let hasError = false

      this.checkPassResult.number = true;
      this.checkPassResult.lowercase = true;
      this.checkPassResult.uppercase = true;
      this.checkPassResult.special = true;
      this.checkPassResult.length = true;
      this.checkPassResult.confirm = true;

      if(!this.hasNumber(this.password)){
        console.log('no num pass');
        this.checkPassResult.number = false;
        hasError = true
      }
      if(!this.hasLowLetter(this.password)){
        console.log('no low letter pass');
        this.checkPassResult.lowercase = false;
        hasError = true
      }
      if(!this.hasUpLetter(this.password)){
        console.log('no Up letter pass');
        this.checkPassResult.uppercase = false;
        hasError = true
      }
      if(!this.hasSpecSumb(this.password)){
        console.log('no spec pass');
        this.checkPassResult.special = false;
        hasError = true
      }
      if(this.password == null){
        console.log('2 wrong pass');
        hasError = true
      }
      if(this.password.length < 8){
        console.log('3 wrong pass');
        this.checkPassResult.length = false;
        hasError = true
      }
      if(this.confirmPassword !== this.password){
        console.log('4 wrong pass');
        this.checkPassResult.confirm = false;
        hasError = true
      }
      return !hasError;
    }
  },

  props: []
}
</script>
