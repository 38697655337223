<template>
  <div>
  <div id="gameListPageDiv" class="max-w-2xl mx-auto m-4">
    
    <div class="grid grid-cols-2 gap-2 mb-5">
      <div class="mt-2">
        <h1 class="text-xl font-bold leading-none text-pink-500 dark:text-white">{{ $t('leaders') }}</h1>
      </div>
      <div class="w-full flex justify-end ">
            <div class="item-right ">
              <router-link :to="{ name: 'GamesList', params: { lang: $i18n.locale }}">
                <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="m12.45 37.65-2.1-2.1L21.9 24 10.35 12.45l2.1-2.1L24 21.9l11.55-11.55 2.1 2.1L26.1 24l11.55 11.55-2.1 2.1L24 26.1Z"/></svg>
              </router-link>
            </div>
          </div>
    </div>
    
    <div class="p-4 border shadow-md sm:p-8 dark:bg-black dark:border-gray-700">
       <div class="flow-root">
            <ul role="list" class="divide-y divide-gray-200 dark:divide-gray-700">
                <li v-for="(ld, row) in leadersData" v-bind:key="row" class="py-3 sm:py-4">
                    
                    <div class="flex text-left space-x-4">
                        <div class="flex-1 min-w-0">
                            <p class="text-sm font-medium truncate dark:text-white">
                                <b>{{ld.un}}</b>
                            </p>
                            <p class="text-sm text-gray-500 truncate dark:text-gray-400">
                              
                              <span v-if="ld.u in gamesPlayedData"> {{$t("user_info.games_played")}}: {{gamesPlayedData[ld.u]}}  </span>
                              <span v-if="ld.u in gamesWonData">, {{$t("user_info.won")}}: {{ gamesWonData[ld.u] }} </span>
                            </p>
                        </div>
                        <div class="inline-flex items-center text-base font-semibold light:text-gray-900 dark:text-white">
                          {{ld.f}}
                        </div>
                    </div>
                  
                </li>

            </ul>
       </div>
    </div>
  </div>


</div>

</template>

<script>
import axios from 'axios'
export default {
  name: 'LeadersTable',
  components: {

  },
  data: function(){
    return {
      leadersData: [],
      gamesPlayedData: [],
      gamesWonData: []
    }
  },
  computed: {

  },
  mounted() {
    document.title = "Leaders Table. In Square Game";
  },
  created() {
    this.getLeaders()
  },
  methods: {
    getLeaders(){
      axios
          .post(this.$backend + '/leaders')
          .then(response => {
            //если ошибка
            console.log(response.data);
            if(typeof response.data.m !== 'undefined'){
              return false;
            }
            this.leadersData = response.data.ps
            response.data.gp.forEach(element => {
              this.gamesPlayedData[element.u]=element.c
            });
            response.data.w.forEach(winner => {
              this.gamesWonData[winner.u]=winner.w
            });
          })
          .catch(error => {
            console.log(error);
            this.$notify({
              group: 'default',
              type: 'error',
              title: 'Server error',
              text: error
            });
          });
    }
  },

  props: []
}
</script>
