<template>
  <div id="app">
    <header class="">
      <nav v-show="showTopNav"  class="container py-2 mx-auto flex items-center justify-center">
        <!-- left header section -->
          <div v-show="joinButton" class="flex items-center space-x-2">
            <a
              href="#join-modal"
              class="flex w-fit h-10 px-4 py-1 text-lg text-white bg-indigo-500"
            >
              <svg
                width="35"
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                  clipRule="evenodd"
                />
              </svg>
              {{ $t("join_btn_title") }}
            </a>
          </div>

          <Player
            v-for="player in players"
            v-bind:player="player"
            v-bind:key="player.id"
            v-bind:playerColor="playersColor[player.id]"
          />
          
          <div class="mx-12">
            <div class="bg-gray-200 p-2">
              <Timer v-bind:user="user"  />
            </div>
            
          </div>
          
        <!-- right header section -->
      </nav>
    </header>
    <div class="md:container md:mx-auto">
      <router-view v-bind:settings="settings" v-bind:user="user"></router-view>
    </div>
    <div id="join-modal" class="modal">
      <div class="modal-box">
        <div class="form-control">
          <label class="label">
            <span class="label-text">{{ $t("username_label") }}</span>
          </label>
          <input
            type="text"
            v-model="username"
            classplaceholder="username"
            class="input input-bordered"
          />
        </div>
        <div class="modal-action">
          <a href="#" @click="joinToGame" class="btn btn-primary">{{
            $t("join_btn_title")
          }}</a>
          <a href="#" class="btn">{{ $t("close_btn_title") }}</a>
        </div>
      </div>
    </div>

    <Modal v-bind:settings="settings" v-bind:user="user"  />

    <notifications position="bottom right" group="default" />

    <vue-up></vue-up>
  </div>
</template>

<style>
@import "daisyui.css";
</style>

<script>
import EventBus from "./event-bus.js";
import Modal from "./components/UX/Modal/Modal.vue";
import Player from "./components/Game/Player.vue";
import axios from 'axios'
import Timer from "./components/UX/Timer.vue";

export default {
  name: "App",
  components: {
    Player,
    Modal, 
    Timer
  },
  //props: ['username'],
  data() {
    return {
      me: {
        name: "Empty",
        id: 0,
        key: "",
      },
      user: {
        id: null,
        token: null,
        name: null,
        email: null
      },
      joinButton: false,
      showTopNav: false,
      settingsButton: true,
      logsButton: false,
      toolsButton: false,
      username: null,
      players: [],
      settings: {
        playMyTurnSound: false,
        showCellCoord: false,
      },
      playersColor: [],
      currentStatus: "lg", //lg - games list
    };
  },
  created() {
    EventBus.$on("successJoinToGame", this.successJoinToGame);
    EventBus.$on("updatePlayers", this.updatePlayers);
    EventBus.$on("updateGameStatus", this.updateGameStatus);
    EventBus.$on("setUserToken", this.setUserToken);
    EventBus.$on("checkUserTokenInCookie", this.checkUserTokenInCookie);
    this.checkUserTokenInCookie()
    this.getMe();
    this.username = this.$cookie.get("username");
  },
  mounted() {
    document.title = "In Square Game";
    //document.body.classList.add('bg-white')
  },
  sockets: {
    connect() {
      // Fired when the socket connects.
      console.log("+++++++connect++++++++++");
      this.$notify({
        group: "default",
        text: this.$t("notify_server_connect"),
      });
    },

    disconnect() {
      console.log("+++++++disconnect++++++++++");
      this.$notify({
        group: "default",
        type: "warn",
        text: this.$t("notify_server_disconnect"),
      });
    },

    updatePlayers() {
      console.log("+++++++updatePlayers++++++++++");
      this.$notify({
        group: "default",
        text: this.$t("notify_new_player_join"),
      });
      EventBus.$emit("updatePlayersFromSocket");
    },

    gameOver() {
      console.log("+++++++gameOver++++++++++");
      EventBus.$emit("gameOver");
    },

    updateSquare() {
      EventBus.$emit("updateSquareFromSocket");
    },

    // Fired when the server sends something on the "messageChannel" channel.
    messageChannel(data) {
      this.socketMessage = data;
    },
  },
  methods: {
    updateGameStatus(status) {
      console.log(status);
      if (status == "ig") {
        //inside game
        this.insideGame();
      }
      if (status == "lg") {
        //inside game
        this.gamesList();
      }
    },
    insideGame() {
      this.showTopNav = true;
      this.joinButton = true;
      this.settingsButton = true;
      this.logsButton = true;
      this.toolsButton = true;
    },
    gamesList() {
      this.showTopNav = false;
      this.joinButton = false;
      this.settingsButton = true;
      this.logsButton = false;
      this.toolsButton = false;
    },
    joinToGame() {
      EventBus.$emit("joinToGame", this.username);
    },
    successJoinToGame(user) {
      this.me = user;
      this.joinButton = false;
    },
    updatePlayers(players, playersColor) {
      this.players = players;
      this.playersColor = playersColor;
    },
    async checkUserTokenInCookie(){
      let usertoken = await this.$cookie.get('usertoken');
      if (usertoken == null) {
          console.log('no usertoken');
          return false;
      }
      await this.setUserToken(usertoken);
    },
    setUserToken(token){
      this.user.token = token
      this.getMe();
    },
    getMe(){
      if(this.user.token == null){
        this.user.name = null
        return false
      }
      axios
          .get(this.$backend + '/user', {
            headers: {
              Authorization: 'Bearer ' + this.user.token //the token is a variable which holds the token
            }
          })
          .then(response => {
            //если ошибка
            console.log(response);
            if(typeof response.data.m !== 'undefined'){
              return false;
            }
            this.user.name = response.data.name
            this.user.email = response.data.email
            this.user.id = response.data.id
            this.settings = response.data.settings
          })
          .catch(error => {
            console.log(error);
            this.$notify({
              group: 'default',
              type: 'error',
              title: 'Server error',
              text: error
            });
          });
    }
  },
};
</script>
