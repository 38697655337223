<template>
  <div v-on:click="buildHere()" v-bind:id="squareData.id" v-bind:class="[squareMainClass, setClass(squareData.o)]">
    <span>{{squareData.p}}</span>
  </div>
</template>

<script>
import EventBus from '../../event-bus.js'
export default {
  name: 'Square',
  data: function(){
    return {
      squareClasses: [],
      ownerClasses: [],
      testOwnerClass: "border-pink-400 bg-blue-200",
      me: {
        name: 'Empty',
        id: 0,
        key: '',
      },
    }
  },
  computed: {

  },
  created() {
    EventBus.$on('successJoinToGame', this.successJoinToGame);
  },
  methods: {
    setClass(owner){
      let cls = null
      if(owner === null){
        cls = "bg-gray-400";
      }
      if(this.squareSize === null){
        this.squareSize = 'normal'
      }
      cls = "text-white hover:border-red-500  ";
      cls = cls + "bg-"+this.getPlayerColor(owner) + " border-"+this.getPlayerColor(owner);
      if (this.squareSize == 'small') {
        cls = cls + ' '+' text-xs h-5 w-5 font-light'
      }else if(this.squareSize == 'normal'){
        cls = cls + ' '+' text-base h-7 w-7 '
      }else if(this.squareSize == 'big'){
        cls = cls + ' '+' text-xl h-10 w-10 '
      }else if(this.squareSize == 'huge'){
        cls = cls + ' '+' text-2xl h-16 w-16 '
      }else{
        this.squareSize = 'small'
      }

      return cls;
    },

    getPlayerColor(player){
      if (this.playersColor[player] != undefined) {
        return this.playersColor[player].color;
      }
      return "gray-400"
    },
    updateSquareOwner(data){
      this.squareData.o = data.o;
    },

    buildHere(){

      if(!this.useWeapon){
        if (this.squareData.o !== null) {

          this.$notify({
            group: 'default',
            type: 'warn',
            //title: 'Important message',
            text: 'You can not build here'
          });
          return false;
        }
      }
      EventBus.$emit('buildHere', this.squareData.id);
    },
    successJoinToGame(user){
      this.me = user;
      //this.$emit('joinToGame');
    },
  },

  props: ['squareData', 'players', 'playersColor', 'row', 'column', 'useWeapon', 'squareSize', 'squareMainClass']
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .column {
      display: table-cell;
  }
</style>
