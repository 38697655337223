<template>
  <div class="flex w-28">
    <div class="flex items-center">
      <div v-bind:class="['bg-'+pColor, 'flex-shrink-0', 'md:w-10 w-5', 'md:h-10 h-5', 'items-center', 'justify-center']">
        <span class="inline-block align-middle m-0 md:m-2  text-center" v-if="this.myQueue">
          <svg width="20" height="20" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="play" class="svg-inline--fa fa-play fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"></path></svg>
        </span>
      </div>
      <div class="md:ml-4 ml-2">
        <span class="md:text-sm border-1 border-green-500  text-xs text-black">{{player.name}}</span>
        <div class="md:text-sm  border-1 border-green-500  text-xs text-gray-500 text-left">{{player.f}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '../../event-bus.js'
export default {
  name: 'Player',
  data: function(){
    return {
      pColor: this.playerColor.color,
      myQueue: false
    }
  },
  computed: {

  },
  created() {
    EventBus.$on('setQueue', this.setQueue);
  },
  methods: {
    setQueue(data){
      if(data.player_id == this.player.id){
        this.myQueue = true
      }else{
        this.myQueue = false
      }
    }
  },

  props: ['player', 'playerColor']
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
