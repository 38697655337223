<template>
    <div class="flex items-center justify-center min-h-screen">
        <div v-if="user.token == null" class="px-8 py-6 mt-4 text-left border-2 shadow-lg md:w-2/4">
          <div class="w-full flex justify-end ">
            <div class="item-right ">
              <router-link :to="{ name: 'GamesList', params: { lang: $i18n.locale }}">
                <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="m12.45 37.65-2.1-2.1L21.9 24 10.35 12.45l2.1-2.1L24 21.9l11.55-11.55 2.1 2.1L26.1 24l11.55 11.55-2.1 2.1L24 26.1Z"/></svg>
              </router-link>
            </div>
          </div>
            <h3 class="text-2xl font-bold text-center">{{ $t("log_into_your_account") }}</h3>

            <div>
                <div class="mt-4">
                    <div>
                        <label class="block" for="email">Email</label>
                                <input type="text" placeholder="" v-model="email"
                                    class="w-full text-black px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600">
                    </div>
                    <div class="mt-4">
                        <label class="block">{{ $t("password") }}</label>
                                <input type="password" placeholder="" v-model="password"
                                    class="w-full px-4 text-black py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600">
                    </div>
                    <div v-if="(errorMessage !== null)" class="mt-4">
                      <span class="text-red-500">{{ $t(errorMessage) }}</span>
                    </div>
                    <div class="flex items-baseline justify-between">
                      <div>
                        <button @click="Login" class="px-6 py-2 mt-4 text-white bg-blue-600 rounded-lg hover:bg-blue-900">{{ $t("login") }}</button>
                      </div>
                      <div>
                      <router-link :to="{ name: 'Registration', params: { lang: $i18n.locale }}">
                          <a href="#" class="text-sm text-blue-600 hover:underline">{{ $t("registration") }}</a>
                        </router-link>
                      </div>
                    </div>
                    <div>
                      <div class="flex items-baseline justify-center mt-10 md:mt-4">
                        <router-link :to="{ name: 'RestorePassEnterEmail', params: { lang: $i18n.locale }}">
                            <a href="#" class="text-sm text-blue-600 hover:underline">{{ $t("forgot_password") }}</a>
                          </router-link>
                        </div>
                      </div>
                </div>
            </div>
        </div>
        <div v-else >
          <h3 class="text-2xl font-bold text-center">{{ $t("you_are_already_logged") }} </h3>
          <div class="flex items-baseline justify-between">
            <button @click="btnBackClick" class="px-6 py-2 mt-4 text-white bg-blue-600 rounded-lg hover:bg-blue-900">{{ $t("back") }}</button>
            <router-link :to="{ name: 'Logout', params: { lang: $i18n.locale, user: user }}">
              <div class="text-sm text-blue-600 hover:underline">{{ $t("logout") }}</div>
            </router-link>
          </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import EventBus from '../../event-bus.js'

export default {
  name: 'Login',
  components: {

  },
  data: function(){
    return {
      email: null,
      password: null,
      errorMessage: null,
      hasError: false
    }
  },
  computed: {

  },
  mounted() {
    document.title = "In Square Game. Login";
    //EventBus.$emit('updateGameStatus', 'lg');
  },
  created() {

  },
  methods: {
    btnBackClick(){
      this.$router.push('/'+this.$i18n.locale)
    },
    async Login(){
      this.errorMessage = null
      this.hasError = false

      if((this.email === null) || (this.email == '')){
        this.errorMessage = 'fill in the Email field'
        this.hasError = true
      }
      if((this.password === null) || (this.password == '') ){
        this.errorMessage = 'fill in the Password field'
        this.hasError = true
      }

      if(this.hasError){
        return false;
      }
      await axios
          .post(this.$backend + '/login', { email: this.email, password: this.password })
          .then(response => {
            //если ошибка

            if(typeof response.data.m !== 'undefined'){
                this.$notify({
                group: 'default',
                type: 'error',
                title: 'Error',
                text: response.data.m
                });
                return false;
            }

            if(typeof response.data.access_token !== 'undefined'){
                if(response.data.access_token !== '' ){
                    console.log(response.data.access_token);
                    EventBus.$emit("setUserToken", response.data.access_token);
                    this.$cookie.set('usertoken', response.data.access_token, { expires: '1Y' });
                    this.$router.push('/'+response.data.lang)

                }
            }
          })
          .catch(error => {
 
            console.log(error.response.status);
            if (error.response.status == 401) {
              this.errorMessage = (error.response.data.message);
              return false;
            }

            this.$notify({
              group: 'default',
              type: 'error',
              title: 'Server error',
              text: error
            });
          });
    },//getLogs
  },

  props: ['user']
}
</script>
