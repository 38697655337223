<template>
    <div class="flex items-center justify-center min-h-screen">
        <div v-if="user.token != null" class="px-8 py-6 mt-4 text-left border-2 shadow-lg md:w-2/4">
          <div class="w-full flex justify-end ">
            <div class="item-right ">
              <router-link :to="{ name: 'GamesList', params: { lang: $i18n.locale }}">
                <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="m12.45 37.65-2.1-2.1L21.9 24 10.35 12.45l2.1-2.1L24 21.9l11.55-11.55 2.1 2.1L26.1 24l11.55 11.55-2.1 2.1L24 26.1Z"/></svg>
              </router-link>
            </div>
          </div>
            <h3 class="text-2xl font-bold text-center">{{ user.name }}</h3>
            <div class="flex justify-center items-center gap-2 my-3">
                <div class="font-semibold text-center mx-4">
                    <p >{{ gp_count }}</p>
                    <span class="text-gray-400">{{ $t("user_info.games_played") }}</span>
                </div>
                <div class="font-semibold text-center mx-4">
                    <p >{{ won_count }}</p>
                    <span class="text-gray-400">{{ $t("user_info.won") }}</span>
                </div>
                <div class="font-semibold text-center mx-4">
                    <p >{{ ps_count }}</p>
                    <span class="text-gray-400">{{ $t("user_info.player_score") }}</span>
                </div>
            </div>
            <div>
                <div class="mt-4">
                    <div>
                        <label class="block" for="name">{{ $t("name")  }}</label>
                                <input type="text" placeholder="" v-model="name"
                                    class="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600">
                    </div>
                    <div>
                        <label class="block" for="email">Email</label>
                                <input type="text" placeholder="" v-model="user.email" disabled="true"
                                    class="w-full bg-gray-200 px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600">
                    </div>
                    <div class="flex items-right justify-between">
                      <div>
                        <button @click="Save" class="px-6 py-2 mt-4 text-white bg-blue-600 rounded-lg hover:bg-blue-900">{{ $t("save") }}</button>
                      </div>
                      <div >
                        <div>
                      <router-link :to="{ name: 'Logout', params: { lang: $i18n.locale }}">
                          <a href="#" class="text-sm text-blue-600 hover:underline">{{ $t("logout") }}</a>
                        </router-link>
                      </div>
                      </div>
                    </div>
                    <div class="flex items-baseline justify-between">
                          <a href="#" class="hidden text-sm text-blue-600 hover:underline">{{ $t("change_password") }}</a>

                    </div>
                </div>
            </div>
        </div>
        <div v-else >
          <h3 class="text-2xl font-bold text-center">{{ $t("you_are_already_logged") }} </h3>
          <div class="flex items-baseline justify-between">
            <button @click="btnBackClick" class="px-6 py-2 mt-4 text-white bg-blue-600 rounded-lg hover:bg-blue-900">{{ $t("back") }}</button>
            <router-link :to="{ name: 'Logout', params: { lang: $i18n.locale, user: user }}">
              <div class="text-sm text-blue-600 hover:underline">{{ $t("logout") }}</div>
            </router-link>
          </div>
        </div>
    </div>
</template>
<script>
 import axios from 'axios'
 import EventBus from '../../event-bus.js'

export default {
  name: 'Profile',
  components: {

  },
  data: function(){
    return {
      email: null,
      name: null,
      password: null,
      errorMessage: null,
      hasError: false,
      gp_count: 0,
      ps_count: 0,
      won_count: 0
    }
  },
  computed: {

  },
  mounted() {
    document.title = "In Square Game. User profile";
    //EventBus.$emit('updateGameStatus', 'lg');
  },
  async created() {
    if( this.user.id == null){
      await EventBus.$emit('checkUserTokenInCookie');
    }

    this.name = this.user.name;

    this.getStat()
  },
  methods: {
    btnBackClick(){
      this.$router.push('/'+this.$i18n.locale)
    },
    async Save(){
      await axios
          .post(this.$backend + '/user/update', { name: this.name }, {
            headers: {
              Authorization: 'Bearer ' + this.user.token //the token is a variable which holds the token
            }
          })
          .then(response => {
            //если ошибка
            console.log(response);
            EventBus.$emit('checkUserTokenInCookie');
          })
          .catch(error => {
 
            console.log(error.response.status);
            if (error.response.status == 401) {
              this.errorMessage = (error.response.data.message);
              return false;
            }

            this.$notify({
              group: 'default',
              type: 'error',
              title: 'Server error',
              text: error
            });
          });
    },
    async getStat(){
      this.errorMessage = null
      this.hasError = false

      if( this.user.id == null){
        return false;
      }
      await axios
          .post(this.$backend + '/user/stat', { user_id: this.user.id })
          .then(response => {
            //если ошибка
            console.log(response);
            this.ps_count = response.data.ps
            this.gp_count = response.data.gp
            this.won_count = response.data.w
          })
          .catch(error => {
 
            console.log(error.response.status);
            if (error.response.status == 401) {
              this.errorMessage = (error.response.data.message);
              return false;
            }

            this.$notify({
              group: 'default',
              type: 'error',
              title: 'Server error',
              text: error
            });
          });
    },//getLogs
  },

  props: ['user']
}
</script>
