import Vue from 'vue'
import Router from 'vue-router'
import GamesList from '@/components/GamesList'
import LeadersTable from '@/components/Game/LeadersTable/Index'
import Play from '@/components/Game/Play'
import Login from '@/components/User/Login'
import Registration from '@/components/User/Registration'
import Logout from '@/components/User/Logout'
import RestorePassEnterEmail from '@/components/User/RestorePassword/EnterEmail'
import NewPassEnter from '@/components/User/RestorePassword/EnterNewPass'
import ValidateEmail from '@/components/User/ValidateEmail'
import Profile from '@/components/User/Profile'
import i18n from '../i18n'

Vue.use(Router)
Vue.use(i18n)

export default new Router({
  mode: 'history',
  routes: [
    {
  path: '/',
  redirect: `/${i18n.locale}`
  },
  {
        path: '/:lang',
        name: 'GamesList',
        component: GamesList,
        props: true,
        meta: {
          title: 'In Square Game. Rooms list'
        }
      },
      {
        path: '/:lang/game/:id',
        name: 'Play',
        component: Play,
        props: true
      },
      {
        path: '/:lang/login',
        name: 'Login',
        component: Login,
        props: true
      },
      {
        path: '/:lang/logout',
        name: 'Logout',
        component: Logout,
        props: true
      },
      {
        path: '/:lang/registration',
        name: 'Registration',
        component: Registration,
        props: true
      },
      {
        path: '/:lang/password/check',
        name: 'RestorePassEnterEmail',
        component: RestorePassEnterEmail,
        props: true
      },
      {
        path: '/:lang/password/update/:key',
        name: 'NewPassEnter',
        component: NewPassEnter,
        props: true
      },
      {
        path: '/:lang/email/validate/:key',
        name: 'ValidateEmail',
        component: ValidateEmail,
        props: true
      },
      {
        path: '/:lang/profile/me',
        name: 'Profile',
        component: Profile,
        props: true
      },
      {
        path: '/:lang/leaders-table',
        name: 'LeadersTable',
        component: LeadersTable,
        props: true
      }
    ]

})
