<template>
  <div class="">
    <div class="grid grid-cols-2 gap-2">
      <div>
        <h3 class="text-white text-2xl font-black shadow-2xl">{{ $t("tools_btn_title") }}</h3>
      </div>
      <div class="text-right">
        <span  @click="closeSlot" class="text-red-500 text-xl  shadow-2xl">{{ $t('close_btn_title') }}</span>
      </div>
    </div>
      <Weapon v-for="(weapon) in tools" v-bind:weapon="weapon" v-bind:key="weapon.id" />
  </div>
</template>
<script>
import Weapon from '../../Game/Arsenal/Weapon.vue'
import EventBus from '../../../event-bus.js'
export default {
  name: 'Arsenal',
  components: {
    Weapon
  },
  data: function(){
    return {
      arsenal: [],
      listData: null,
      drawerShow: false,
      colors: ['bg-green-200', 'bg-purple-400', 'bg-purple-200','bg-blue-200', 'bg-indigo-500', 'bg-purple-400', 'bg-pink-400', 'bg-red-500', 'bg-black', 'bg-yellow-300']
    }
  },
  computed: {

  },
  mounted() {

  },
  created() {

  },
  methods: {
    closeSlot() {
      EventBus.$emit('showControlItem', 'home');
    },
    tog() {
      this.drawerShow = !this.drawerShow;
    }
  },
  props: {
    tools: Array
  }
}
</script>
