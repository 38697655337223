<template>
  <div class="">
    <div class="grid grid-cols-2 gap-2">
      <div>
        <h3 class="text-white text-2xl font-black shadow-2xl">{{ $t("settings.title") }}</h3>
      </div>
      <div class="text-right">
        <span  @click="closeSlot" class="text-red-500 text-xl  shadow-2xl">{{ $t('close_btn_title') }}</span>
      </div>
    </div>
    <div class="bg-white dark:bg-gray-400 md:p-12">
      <div class="form-control">
        <label class="label">
          <input type="checkbox" v-model="settings.playMyTurnSound" class="checkbox checkbox-secondary">
          <span class="label-text text-black">{{ $t('settings.play_sound_label')}}</span>
        </label>
        <label class="label">
          <input type="checkbox" v-model="settings.showCellCoord"  class="checkbox checkbox-secondary">
          <span class="label-text text-black">{{ $t('settings.cells_coord_label')}}</span>
        </label>
        <hr>
        <LanguageSwitcher />
        <SizeSwitcher v-bind:squareSize="squareSize" />

      </div>
    </div>
  </div>
</template>
<script>
import EventBus from '../../../event-bus.js'
import LanguageSwitcher from '../../UX/LanguageSwitcher.vue'
import SizeSwitcher from '../../UX/SizeSwitcher.vue'
export default {
  name: 'Settings',
  components: {
    LanguageSwitcher, SizeSwitcher
  },
  data: function(){
    return {
      // players: [],
      // playerColor: []
    }
  },
  computed: {

  },
  mounted() {

  },
  created() {
  
  },
  methods: {
    closeSlot() {
      EventBus.$emit('showControlItem', 'home');
    }

  },
  props: ["settings", "squareSize"]
}
</script>
