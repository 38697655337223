<template>
    <div class="flex items-center justify-center min-h-screen">
        <div v-if="successLogout" >
          <h3 class="text-2xl font-bold text-center">{{ $t("log_out_of_your_account") }}</h3>
          <div class="flex items-baseline justify-between">
            <button @click="btnBackClick" class="text-sm text-blue-600 hover:underline">{{ $t("back") }}</button>
            <a>
              <div @click="logout" class="px-6 py-2 mt-4 text-white bg-blue-600 rounded-lg hover:bg-blue-900">{{ $t("yes") }}</div>
            </a>
            
          </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import EventBus from '../../event-bus.js'

export default {
  name: 'Logout',
  components: {

  },
  data: function(){
    return {
      successLogout: true
    }
  },
  computed: {

  },
  mounted() {
    document.title = "In Square Game. Logout";
    //EventBus.$emit('updateGameStatus', 'lg');
  },
  created() {
    
  },
  methods: {
    btnBackClick(){
      this.$router.push('/'+this.$i18n.locale)
    },
    logout(){
      if(this.user.token === null){
        console.log('no token');
        return false;
      }

      if(this.user.token === ''){
        console.log('empty token');
        return false;
      }
      axios
          .get(this.$backend + '/logout',{
            headers: {
              Authorization: 'Bearer ' + this.user.token //the token is a variable which holds the token
            }
          })
          .then( response =>  {
            //если ошибка
            
            if(typeof response.data.m !== 'undefined'){
                this.$notify({
                group: 'default',
                type: 'error',
                title: 'Error',
                text: response.data.m
                });
                return false;
            }
            
            if(response.data.status == 'OK'){
                  EventBus.$emit("setUserToken", null);
                  this.$cookie.delete('usertoken');
                  this.$router.push('/'+this.$i18n.locale)
            }else{
              alert(response.data.status)
            }
          })
          .catch(error => {
            console.log(error);
            this.$notify({
              group: 'default',
              type: 'error',
              title: 'Server error',
              text: error
            });
          });
    },//getLogs
  },

  props: ['user']
}
</script>
