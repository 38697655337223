<template>
  <div >
    <div class="grid grid-cols-2 gap-2">
      <div>
        <h3 class="text-white text-2xl font-black shadow-2xl">{{ $t("log_btn_title") }}</h3>
      </div>
      <div class="text-right">
        <span  @click="closeSlot" class="text-red-500 text-xl  shadow-2xl">{{ $t('close_btn_title') }}</span>
      </div>
    </div>
    <div class="bg-white w-96">
      <div v-on:click="getLogs()" class="btn btn-outline btn-primary">
        <span>{{ $t('update_btn_title')}}</span>
      </div>
      <div class="overflow-y-auto max-h-64">
        <LogItem v-for="(sd, row) in logData" v-bind:squareData="sd" v-bind:key="row"  v-bind:playerColor=getPlayerColor(sd.o)
                                              v-bind:players="players" />
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import EventBus from '../../../event-bus.js'
import LogItem from './LogItem.vue'

export default {
  name: 'Logs',
  components: {
    LogItem
  },
  data: function(){
    return {
      logData: null,
      squareMainClass: "column h-12 w-12 border-2 inline-block align-middle text-white text-2xl font-extrabold hover:bg-red-200 hover:border-red-500 ",
      squareClasses: [],
      ownerClasses: [],
      squareWaterClass: "bg-blue",
      squareForestClass: "bg-green",
      squareDesertClass: "bg-yellow",
      testOwnerClass: "border-pink-400 bg-blue-200",
      me: {
        name: 'Empty',
        id: 0,
        key: '',
      },
    }
  },
  computed: {

  },
  created() {
    this.getLogs()
    EventBus.$on('successJoinToGame', this.successJoinToGame);
  },
  methods: {
    closeSlot() {
      EventBus.$emit('showControlItem', 'home');
    },
    setClass(owner, type){
      if(owner === null){
        return this.squareClasses.get(type).class+"-200";
      }
      return "bg-"+this.getPlayerColor(owner) + " border-"+this.getPlayerColor(owner);

    },
    setIcon(type){
      return this.squareClasses.get(type).icon;
    },
    createSquareClasses(){
      this.squareClasses= new Map([
        [1, {class: this.squareForestClass}],
        [2, {class: this.squareDesertClass}],
        [3, {class: this.squareWaterClass}]
      ]);
    },
    getPlayerColor(player){
      if (this.playersColor[player] != undefined) {
        return this.playersColor[player].color;
      }
      return "bg-gray-400"
    },
    updateSquareOwner(data){
      this.squareData.o = data.o;
    },
    successJoinToGame(user){
      this.me = user;

      //this.$emit('joinToGame');
    },
    getLogs(){  
      if (this.myKey == '') {
        return false;
      }
      axios
          .post(this.$backend + '/game/logs/get', { key: this.myKey })
          .then(response => {
            //если ошибка
            //console.log(response.data);
            if(typeof response.data.m !== 'undefined'){
              this.$notify({
                group: 'default',
                type: 'warn',
                //title: 'Important message',
                text: response.data.m
              });
              return false;
            }
            this.logData = response.data
            this.$notify({
              group: 'default',
              text: 'Successfully'
            });
          })
          .catch(error => {
            console.log(error);
            this.$notify({
              group: 'default',
              type: 'error',
              title: 'Server error',
              text: error
            });
          });
    },//getLogs
  },

  props: ['myKey', 'players', 'playersColor']
}
</script>