<template>
  <svg
     xmlns:dc="http://purl.org/dc/elements/1.1/"
     xmlns:cc="http://creativecommons.org/ns#"
     xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
     xmlns:svg="http://www.w3.org/2000/svg"
     xmlns="http://www.w3.org/2000/svg"
     xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
     xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
     xml:space="preserve"
     width="100mm"
     height="100mm"
     version="1.1"
     style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
     viewBox="0 0 10000 10000"
     id="svg126"
     sodipodi:docname="win (2).svg"
     inkscape:version="0.92.5 (2060ec1f9f, 2020-04-08)"><metadata
     id="metadata130"><rdf:RDF><cc:Work
         rdf:about=""><dc:format>image/svg+xml</dc:format><dc:type
           rdf:resource="http://purl.org/dc/dcmitype/StillImage" /></cc:Work></rdf:RDF></metadata><sodipodi:namedview
     pagecolor="#ffffff"
     bordercolor="#666666"
     borderopacity="1"
     objecttolerance="10"
     gridtolerance="10"
     guidetolerance="10"
     inkscape:pageopacity="0"
     inkscape:pageshadow="2"
     inkscape:window-width="1858"
     inkscape:window-height="1016"
     id="namedview128"
     showgrid="false"
     inkscape:zoom="1.9228315"
     inkscape:cx="317.8967"
     inkscape:cy="150.5405"
     inkscape:window-x="62"
     inkscape:window-y="27"
     inkscape:window-maximized="1"
     inkscape:current-layer="svg126" />
   <defs
     id="defs17">
      <clipPath
     id="id0">
       <path
     d="M4011.57 944.59c816.71,-65.19 1368.08,-59.48 2181.98,0.63 988.33,72.99 1370.22,138.92 2409.27,396.41 506.69,125.51 1095.61,268.27 1095.61,851.25l0 4365.89c0,406.09 -369.43,738.34 -820.97,738.34l-7738.19 0c-451.54,0 -818.72,-332.26 -820.98,-738.34l-24.3 -4358.61c-1.1,-198.32 58.02,-371.28 226.3,-510.03 730.46,-602.26 2795.18,-689.97 3491.28,-745.54z"
     id="path4" />
      </clipPath>
    <linearGradient
     id="id1"
     gradientUnits="userSpaceOnUse"
     x1="5155.36"
     y1="897.27"
     x2="4837.04"
     y2="7297.73">
     <stop
     offset="0"
     style="stop-opacity:1; stop-color:#6366F1"
     id="stop7" />
     <stop
     offset="1"
     style="stop-opacity:1; stop-color:#BFDBFE"
     id="stop9" />
    </linearGradient>
    <linearGradient
     id="id2"
     gradientUnits="userSpaceOnUse"
     x1="4705.87"
     y1="3820.17"
     x2="5161.11"
     y2="9112.14">
     <stop
     offset="0"
     style="stop-opacity:1; stop-color:#718FC8"
     id="stop12" />
     <stop
     offset="1"
     style="stop-opacity:1; stop-color:#B7D1FD"
     id="stop14" />
    </linearGradient>
  </defs>
   <path
     class="fil0 str0"
     d="m 5765.59,9071.12 c -690.2,53.87 -1156.15,49.15 -1843.97,-0.51 C 3086.4,9010.3 2763.67,8955.83 1885.58,8743.08 1457.38,8639.39 959.69,8521.44 959.69,8039.77 V 4432.62 c 0,-335.51 312.2,-610.02 693.79,-610.02 h 6539.46 c 381.58,0 691.88,274.52 693.79,610.02 l 20.54,3601.13 c 0.93,163.86 -49.03,306.77 -191.24,421.4 -617.31,497.6 -2362.18,570.06 -2950.44,615.97 z"
     id="path20"
     style="fill:url(#id2);stroke:#e6e7e8;stroke-width:20;stroke-miterlimit:22.92560005"
     inkscape:connector-curvature="0" /><path
     class="fil1"
     d="m 4011.57,944.59 c 816.71,-65.19 1368.08,-59.48 2181.98,0.63 988.33,72.99 1370.22,138.92 2409.27,396.41 506.69,125.51 1095.61,268.27 1095.61,851.25 v 4365.89 c 0,406.09 -369.43,738.34 -820.97,738.34 H 1139.27 c -451.54,0 -818.72,-332.26 -820.98,-738.34 l -24.3,-4358.61 c -1.1,-198.32 58.02,-371.28 226.3,-510.03 730.46,-602.26 2795.18,-689.97 3491.28,-745.54 z"
     id="path22"
     style="fill:url(#id1)"
     inkscape:connector-curvature="0" /><path
     class="fil3 str1"
     d="m 4011.57,944.59 c 816.71,-65.19 1368.08,-59.48 2181.98,0.63 988.33,72.99 1370.22,138.92 2409.27,396.41 506.69,125.51 1095.61,268.27 1095.61,851.25 v 4365.89 c 0,406.09 -369.43,738.34 -820.97,738.34 H 1139.27 c -451.54,0 -818.72,-332.26 -820.98,-738.34 l -24.3,-4358.61 c -1.1,-198.32 58.02,-371.28 226.3,-510.03 730.46,-602.26 2795.18,-689.97 3491.28,-745.54 z"
     id="path34"
     inkscape:connector-curvature="0"
     style="fill:none;stroke:#ffffff;stroke-width:20;stroke-miterlimit:22.92560005" /><g
     id="g38">
      <path
     class="fil4 str2"
     d="m 2472.1,4980.86 h 5055.31 c 332.56,0 604.65,272.09 604.65,604.65 v 255.31 c 0,332.56 -272.09,604.65 -604.65,604.65 H 2472.1 c -332.56,0 -604.65,-272.09 -604.65,-604.65 v -255.31 c 0,-332.56 272.09,-604.65 604.65,-604.65 z"
     id="path36"
     inkscape:connector-curvature="0"
     style="fill:#fefefe;stroke:#fefefe;stroke-width:20;stroke-linejoin:round;stroke-miterlimit:22.92560005" />
     </g><rect
     class="fil5 str3"
     x="1999.75"
     y="5113.1699"
     width="6000"
     height="1200"
     rx="472.34"
     ry="472.34"
     id="rect40"
     style="fill:#fefefe;stroke:#6366f1;stroke-width:20;stroke-linejoin:round;stroke-miterlimit:22.92560005" /><rect
     class="fil7 str4"
     x="2990.3899"
     y="7553.0898"
     width="3952.9399"
     height="1085.4"
     rx="311.19"
     ry="427.23001"
     id="rect50"
     style="fill:#bfdbfe;stroke:#6366f1;stroke-width:20;stroke-miterlimit:22.92560005" /><g
     id="g54">
      <path
     class="fil4 str5"
     d="M 3417.62,7632.26 H 6516.1 c 191.44,0 348.06,156.62 348.06,348.06 v 230.93 c 0,191.44 -156.62,348.06 -348.06,348.06 H 3417.62 c -191.44,0 -348.06,-156.62 -348.06,-348.06 v -230.93 c 0,-191.44 156.62,-348.06 348.06,-348.06 z"
     id="path52"
     inkscape:connector-curvature="0"
     style="fill:#fefefe;stroke:#fefefe;stroke-width:20;stroke-miterlimit:22.92560005" />
     </g><path
     class="fil6"
     d="m 3441.58,8185.41 h -100.99 v -82.36 h 100.64 c 14.46,0 26.03,-3.13 34.94,-9.49 8.79,-6.36 15.15,-15.04 19.32,-26.26 4.05,-11.22 6.01,-23.72 6.01,-37.83 0,-14.23 -1.96,-27.53 -6.01,-39.91 -4.17,-12.49 -10.53,-22.44 -19.32,-30.08 -8.91,-7.52 -20.36,-11.33 -34.59,-11.33 h -69.18 v 411.13 h -98.9 v -493.5 h 168.08 c 33.2,0 61.78,7.06 85.72,21.06 23.95,13.99 42.34,33.31 55.07,57.84 12.72,24.41 19.2,52.52 19.2,84.21 0,31.58 -6.48,59.12 -19.32,82.6 -12.96,23.6 -31.23,41.76 -55.06,54.6 -23.83,12.84 -52.41,19.32 -85.61,19.32 z"
     id="path56"
     inkscape:connector-curvature="0"
     style="fill:#6366f1;fill-rule:nonzero" /><path
     id="path58"
     class="fil6"
     d="m 3941.78,8277.26 v 82.02 h -205.33 l 0.23,-82.02 z m -174.91,-411.48 0.35,493.5 h -99.25 v -493.5 z"
     inkscape:connector-curvature="0"
     style="fill:#6366f1;fill-rule:nonzero" /><path
     id="path60"
     class="fil6"
     d="m 4185.18,7953.93 -106.08,405.35 h -104.81 l 154.2,-493.5 h 65.48 z m 83.64,405.35 -106.78,-405.35 -9.83,-88.15 h 66.52 l 155.47,493.5 z m 3.81,-184.05 v 82.37 h -222.68 v -82.37 z"
     inkscape:connector-curvature="0"
     style="fill:#6366f1;fill-rule:nonzero" /><polygon
     id="polygon62"
     class="fil6"
     points="4568.77,8179.97 4568.77,8359.28 4468.71,8359.28 4468.71,8179.97 4335.91,7865.78 4443.72,7865.78 4518.92,8088.12 4593.41,7865.78 4701.58,7865.78 "
     style="fill:#6366f1;fill-rule:nonzero" /><path
     id="path64"
     class="fil6"
     d="m 5085.64,7953.93 -106.08,405.35 h -104.81 l 154.2,-493.5 h 65.48 z m 83.64,405.35 -106.78,-405.35 -9.83,-88.15 h 66.52 l 155.47,493.5 z m 3.81,-184.05 v 82.37 h -222.68 v -82.37 z"
     inkscape:connector-curvature="0"
     style="fill:#6366f1;fill-rule:nonzero" /><path
     id="path66"
     class="fil6"
     d="m 5649.48,8100.04 v 203.37 c -6.95,7.4 -17.24,16.08 -30.66,25.91 -13.42,9.83 -31,18.39 -52.52,25.68 -21.63,7.4 -47.89,11.11 -78.78,11.11 -27.42,0 -52.17,-4.4 -74.38,-12.96 -22.33,-8.56 -41.42,-21.63 -57.26,-39.1 -15.97,-17.47 -28.12,-39.45 -36.44,-65.71 -8.45,-26.37 -12.61,-57.61 -12.61,-93.82 v -83.98 c 0,-35.75 4.05,-66.87 12.03,-93.36 8.1,-26.6 19.78,-48.7 35.05,-66.28 15.38,-17.59 33.78,-30.77 55.18,-39.33 21.28,-8.56 45.46,-12.84 72.3,-12.84 37.02,0 67.56,6.24 91.39,18.62 23.83,12.38 41.88,30.89 54.14,55.3 12.38,24.29 19.78,54.25 22.21,89.77 h -95.9 c -1.62,-20.83 -4.98,-37.02 -10.3,-48.82 -5.32,-11.8 -12.72,-20.13 -22.21,-25.22 -9.48,-5.09 -21.86,-7.64 -36.9,-7.64 -13.19,0 -24.53,2.32 -34.24,6.94 -9.72,4.63 -17.93,12.15 -24.41,22.56 -6.59,10.41 -11.45,23.72 -14.58,39.91 -3.24,16.31 -4.74,36.21 -4.74,59.69 v 84.68 c 0,23.37 1.74,43.27 5.44,59.7 3.58,16.54 8.9,29.96 16.08,40.37 7.05,10.41 16.08,17.93 27.07,22.56 10.99,4.62 23.94,6.94 38.87,6.94 11.91,0 21.51,-1.28 28.8,-3.82 7.17,-2.43 12.96,-5.09 17.35,-7.87 4.51,-2.89 8.1,-5.09 11.11,-6.71 v -90.81 h -70.92 v -74.84 z"
     inkscape:connector-curvature="0"
     style="fill:#6366f1;fill-rule:nonzero" /><path
     id="path68"
     class="fil6"
     d="m 5899.35,7953.93 -106.08,405.35 h -104.81 l 154.2,-493.5 h 65.48 z m 83.64,405.35 -106.78,-405.35 -9.83,-88.15 h 66.52 l 155.47,493.5 z m 3.81,-184.05 v 82.37 h -222.68 v -82.37 z"
     inkscape:connector-curvature="0"
     style="fill:#6366f1;fill-rule:nonzero" /><polygon
     id="7"
     class="fil6"
     points="6238.18,7865.78 6238.18,8359.28 6139.62,8359.28 6139.62,7865.78 "
     style="fill:#6366f1;fill-rule:nonzero" /><polygon
     id="8"
     class="fil6"
     points="6416.79,8359.28 6322.63,8359.28 6322.63,7865.78 6417.14,7865.78 6573.42,8178.01 6573.42,7865.78 6667.59,7865.78 6667.59,8359.28 6571.69,8359.28 6416.79,8050.53 "
     style="fill:#6366f1;fill-rule:nonzero" /><path
     style="fill:#fefefe;fill-rule:nonzero"
     inkscape:connector-curvature="0"
     id="path72"
     d="m 4168.84,3686.94 c -20.57,-5.6 -39.33,7.57 -43.11,28.14 -3.79,16.96 -15.34,56.46 -15.52,60.25 -49.61,128.98 -168.67,219.72 -305.4,234.33 -134.58,14.07 -265.54,-46.72 -341.49,-156.04 l 69.45,15.33 c 18.76,3.79 39.33,-7.57 43.12,-28.14 3.78,-18.76 -7.58,-39.32 -28.14,-43.11 l -146.3,-31.93 c -9.38,-1.81 -38.61,0 -45.1,28.14 l -31.93,148.28 c -3.79,18.76 7.57,39.33 28.14,43.12 22.91,2.34 39.33,-11.19 45.1,-29.95 l 11.18,-50.87 c 83.52,113.11 234.33,196.81 403.54,178.59 170.65,-18.4 315.33,-133.31 371.61,-294.58 1.81,-4.69 10.28,-44.02 13.17,-58.27 5.59,-20.74 -7.58,-39.5 -28.32,-43.29 z"
     class="fil8" /><path
     class="fil8"
     d="m 3406.86,3373.06 c 7.58,3.78 29.04,15.15 52.49,-11.19 9.38,-13.17 18.76,-26.34 29.95,-39.32 13.17,-14.98 11.18,-39.33 -3.79,-52.5 -14.97,-13.17 -39.33,-11.18 -52.49,3.79 -13.17,14.97 -26.34,31.93 -37.53,48.71 -11,16.77 -5.41,39.14 11.37,50.51 z"
     id="path74"
     inkscape:connector-curvature="0"
     style="fill:#fefefe;fill-rule:nonzero" /><path
     class="fil8"
     d="m 3772.88,3196.63 c 35.71,0 71.25,5.59 105.17,16.96 22.73,6.67 39.32,-7.58 46.9,-22.55 5.59,-20.57 -5.59,-41.31 -24.35,-46.9 -41.32,-13.17 -82.62,-20.57 -125.74,-20.57 -20.56,0 -37.52,14.97 -37.52,35.72 0,20.74 14.97,37.34 35.54,37.34 z"
     id="path76"
     inkscape:connector-curvature="0"
     style="fill:#fefefe;fill-rule:nonzero" /><path
     class="fil8"
     d="m 4106.96,3425.55 c 13.17,31.93 20.57,65.66 24.36,99.4 0,0 2.16,41.85 41.31,35.71 38.78,-5.05 33.73,-43.11 33.73,-43.11 -3.79,-41.31 -14.97,-82.62 -29.94,-120.14 -9.38,-18.76 -29.95,-28.14 -48.71,-20.57 -18.94,9.38 -28.32,29.95 -20.75,48.71 z"
     id="path78"
     inkscape:connector-curvature="0"
     style="fill:#fefefe;fill-rule:nonzero" /><path
     class="fil8"
     d="m 3971.85,3260.49 c 29.95,20.57 56.28,45.1 78.83,73.24 16.78,20.93 45.1,12.27 50.69,5.59 14.98,-13.17 18.76,-37.52 5.6,-52.49 -26.34,-31.93 -58.27,-61.88 -93.81,-86.41 -16.96,-11.18 -39.33,-7.58 -50.69,9.38 -11.36,16.96 -7.58,39.33 9.38,50.69 z"
     id="path80"
     inkscape:connector-curvature="0"
     style="fill:#fefefe;fill-rule:nonzero" /><path
     class="fil8"
     d="m 3566.51,3254.72 c 29.94,-18.76 63.86,-33.74 97.59,-43.12 20.56,-5.59 31.93,-26.33 26.34,-45.09 -5.6,-20.57 -26.34,-31.93 -45.1,-26.34 -41.31,11.18 -80.64,28.14 -116.36,50.69 -16.95,11.18 -22.54,33.73 -11.18,50.69 7.4,11.36 23.27,22.55 48.71,13.17 z"
     id="path82"
     inkscape:connector-curvature="0"
     style="fill:#fefefe;fill-rule:nonzero" /><path
     class="fil8"
     d="m 3620.62,3601.97 h 173 c 20.57,0 35.72,-16.95 37.52,-37.52 v -256.52 c 0,-20.56 -16.95,-37.52 -37.52,-37.52 -20.56,0 -37.52,16.96 -37.52,37.52 v 219 h -135.48 c -20.56,0 -37.52,16.96 -37.52,37.52 0,20.57 16.78,37.52 37.52,37.52 z"
     id="path84"
     inkscape:connector-curvature="0"
     style="fill:#fefefe;fill-rule:nonzero" />
  <text
     xml:space="preserve"
     style="font-style:normal;font-variant:normal;font-weight:bold;font-stretch:normal;font-size:1058.33337402px;line-height:1.25;font-family:FreeMono;-inkscape-font-specification:'FreeMono, Bold';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:start;letter-spacing:0px;word-spacing:0px;writing-mode:lr-tb;text-anchor:start;fill:#bfdbfe;fill-opacity:1;stroke:none;stroke-width:26.45833206"
     x="2964.3604"
     y="2291.4136"
     id="text939"><tspan
       sodipodi:role="line"
       id="tspan937"
       x="2964.3604"
       y="2291.4136"
       style="font-style:normal;font-variant:normal;font-weight:bold;font-stretch:normal;font-size:1058.33337402px;font-family:FreeMono;-inkscape-font-specification:'FreeMono, Bold';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:start;writing-mode:lr-tb;text-anchor:start;fill:#bfdbfe;fill-opacity:1;stroke-width:26.45833206">YOU WIN</tspan></text>
  <text
     xml:space="preserve"
     style="font-style:normal;font-variant:normal;font-weight:bold;font-stretch:normal;font-size:887.89691162px;line-height:1.25;font-family:FreeMono;-inkscape-font-specification:'FreeMono, Bold';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:start;letter-spacing:0px;word-spacing:0px;writing-mode:lr-tb;text-anchor:start;fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:22.19742012"
     x="4693.9302"
     y="3695.5093"
     id="text1092"
     transform="scale(0.95278059,1.0495596)"><tspan
       sodipodi:role="line"
       id="tspan1090"
       x="4693.9302"
       y="3695.5093"
       style="font-style:normal;font-variant:normal;font-weight:bold;font-stretch:normal;font-size:887.89691162px;font-family:FreeMono;-inkscape-font-specification:'FreeMono, Bold';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:start;writing-mode:lr-tb;text-anchor:start;fill:#ffffff;stroke-width:22.19742012">{{ playTime }} min</tspan></text>
  <text
     xml:space="preserve"
     style="font-style:normal;font-variant:normal;font-weight:bold;font-stretch:normal;font-size:1058.33337402px;line-height:1.25;font-family:FreeMono;-inkscape-font-specification:'FreeMono, Bold';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:start;letter-spacing:0px;word-spacing:0px;writing-mode:lr-tb;text-anchor:start;fill:#6366f1;fill-opacity:1;stroke:none;stroke-width:26.45833206"
     x="3907.8652"
     y="6023.334"
     id="text1096"><tspan
       sodipodi:role="line"
       id="tspan1094"
       x="3907.8652"
       y="6023.334"
       style="font-style:normal;font-variant:normal;font-weight:bold;font-stretch:normal;font-size:1058.33337402px;font-family:FreeMono;-inkscape-font-specification:'FreeMono, Bold';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:start;writing-mode:lr-tb;text-anchor:start;fill:#6366f1;fill-opacity:1;stroke-width:26.45833206">{{ playerScore }}</tspan></text>
  </svg>

</template>
<script>

export default {
  name: 'Win',
  components: {

  },
  data: function(){
    return {

    }
  },
  computed: {

  },
  mounted() {

  },
  created() {

  },
  methods: {

  },
  props: {
    playTime: {
      type: String,
      default: '00:00'
    },
    playerScore: {
       type: [Number, String],
      default: '0'
    },
  }
}
</script>
