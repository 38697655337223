<template>
  <div>
    <ModalSettings v-bind:settings="settings" v-bind:user="user"  />
    <ModalLogs />
  </div>

</template>
<script>
import ModalSettings from './ModalSettings.vue'
import ModalLogs from './ModalLogs.vue'
export default {
  name: 'Modal',
  components: {
    ModalSettings, ModalLogs
  },
  data: function(){
    return {

    }
  },
  computed: {

  },
  mounted() {

  },
  created() {

  },
  methods: {

  },
  props: ["settings", "user"]
}
</script>
